:root {
  --primary-color: #2980b9;
  --secondary-color: #2c3e50;
  --tertiary-color: #34495e;
  --details-color: #7f8c8d;
}
li {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  color: var(--primary-color);
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: bold;
}

.secondary-title {
  color: var(--secondary-color);
  font-size: 1.5rem;
}

.tertiary-title {
  color: var(--secondary-color);
  font-size: 1rem;
}

.audit-details {
  color: var(--details-color);
  font-size: 0.75rem;
}

.inactive {
  color: var(--bs-danger);
  font-weight: 700;
  font-size: large;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.m10 {
  margin: 10px;
}
.mt10 {
  margin: 10px 0;
}
.ml10 {
  margin: 0 10px;
}
.m20 {
  margin: 20px;
}
.mt20 {
  margin: 20px 0;
}
.ml20 {
  margin: 0 20px;
}
.m30 {
  margin: 30px;
}
.mt30 {
  margin: 30px 0;
}
.ml30 {
  margin: 0 30px;
}

.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.pt10 {
  padding-top: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}

.form {
  margin-bottom: 3rem;
}
.form .card form {
  margin-top: 2rem;
}

.form .card .field {
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}

.start {
  align-items: flex-start;
}

.end {
  justify-content: flex-end;
}

.align-end {
  align-items: end;
}

.pointer {
  cursor: pointer;
}

.dialog {
  min-width: 500px;
  max-width: 500px;
}

.pos-row {
  color: var(--bs-blue) !important;
}

@media screen and (max-width: 500px) {
  .half-width {
    width: 100%;
  }
  .dialog {
    min-width: 350px !important;
  }
  .hide-on-mobile {
    display: none;
  }
}

.no-display {
  display: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.no-link {
  text-decoration: none;
  color: var(--details-color);
}

.lighter {
    font-weight: lighter;
}

.favorite {
  color: gold;
  cursor: pointer;
}

.non-favorite {
  cursor: pointer;
}

.negative-balance {
  color: var(--bs-danger);
}

.debug {
  border: 1px solid red;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
